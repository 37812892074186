<template>
  <div>
    <a-space class="w-full" :size="20" direction="vertical">
      <div class="p-20 bg-white" v-for="(data, index) in origin" :key="index">
        <Solution :dataSource="data" @update="update" />
      </div>
    </a-space>
  </div>
</template>

<script>
import Solution from "./solution.vue";
import { getSolutions } from "@/api";
import { getProSolutionDetail, getCompanySolutions } from "@/api/solution.js";
export default {
  components: {
    Solution,
  },
  data() {
    return {
      origin: [],
      status: {},
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
  },
  created() {
    const id = this.$route.params.id;
    this.id = id;
    this.update();
  },
  methods: {
    update() {
      if (this.id) {
        this.getProSolutionDetail(this.id);
      } else {
        this.getRecoginze();
      }
    },
    getRecoginze() {
      getCompanySolutions(this.userInfo.company_id).then(({ data }) => {
        const temp = data.rows || [];
        this.origin = temp;
      });
    },
    getProSolutionDetail(id) {
      getProSolutionDetail(id).then(({ data }) => {
        this.origin = [data];
      });
    },
  },
};
</script>